import { i18nEn } from './en'

export const clientI18nEn = {
  ...i18nEn,
  tagLine: `Hire Israel’s Best Freelance Developers, Fast.`,
  subtitle: `We've built a vetted network of Israel’s top freelance developers, sourced from elite IDF units such as 8200, major open-source software contributors, and top universities.`,
  step1: `Tell us what kind of developer you need`,
  step2: `We find you the perfect match`,
  step3: `They become part of your team`,
  feature1: {
    header: 'Selected by Experts',
    text: `We’ve interviewed hundreds of developers, and worked as freelancers ourselves. We know what it takes to find the right people.`,
  },
  feature2: {
    header: 'Top Talent',
    text: `Every Skilled developer has passed rigorous tests and background checks to ensure they're right for the job.`,
  },
  feature3: {
    header: 'Hire Quickly',
    text: `Our database includes hundreds of prevetted freelancers. We'll find you the developers you need within two days.`,
  },
  // feature3: {
  //   header: 'Start Risk Free',
  //   text: `We put our money where our mouths are. We offer a free trial period. If you’re not happy with the results you don’t pay.`,
  // },
}
