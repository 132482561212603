import { graphql } from 'gatsby'
import * as React from 'react'

import LandingPage, { LandingPageProps } from '../components/pages/LandingPage'
import { clientI18nEn } from '../language/clientEn'
import DefaultLayout from '../layouts'

const Index = (props: LandingPageProps) => (
  <DefaultLayout language="en">
    <LandingPage
      i18n={clientI18nEn}
      {...props}
      otherLanguageUrl="/he"
      languagePrefix="/"
      isClient
    />
  </DefaultLayout>
)

export default Index

export const pageQuery = graphql`
  query {
    ...SiteData
  }
`
