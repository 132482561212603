import * as React from 'react'

export const i18nEn = {
  trustedBy: 'TRUSTED BY',
  howDoesSkilledWork: (
    <>
      HOW DOES <strong>SKILLED</strong> WORK?
    </>
  ),
  hireDeveloper: `FIND A DEVELOPER`,
  applyAsDeveloper: 'JOIN AS A DEVELOPER',
  applyNow: 'APPLY NOW',
  israelsFinest: (
    <>
      OUR FREELANCERS <strong>SPECIALISE</strong> IN
    </>
  ),
  vettingProcess: {
    step1: 'Resume Review',
    step2: 'Take-home Project',
    step3: 'Technical Interview',
    step4: 'Reference Checks',
  },
  hire: 'Hire',
  apply: 'Join as a Freelancer',
  contact: 'Contact',
  aboutUs: 'About Us',
  blog: 'Blog',
  otherLanguage: 'עברית',
  // joinSkilledCommunity: `JOIN THE SKILLED COMMUNITY`,
}
